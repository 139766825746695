import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/challenge-7/",
  "date": "2020-09-04T00:00:00.000Z",
  "title": "Offline rapport",
  "blurb": "My challenges and victories whilst attempting challenge #7",
  "tags": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Call me naïve but why do I need offline support for my blog website? Like literally no-one is going to download it to their home screens so why do I need to complete this seemingly redundant objective?`}</p>
    <p>{`I’ve been entertaining this thought throughout the week of this challenge but I guess it’s just another skill to add to my arsenal. Although this task was basically to read two/three docs about PWA’s and install gatsby plugins manifest and offline. Then the usual listing them in the gatsby-config file, stating manifest prior to offline.`}</p>
    <p>{`Kinda goes to say, this task went on without a hitch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      